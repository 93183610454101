import { Component, Vue } from 'vue-property-decorator';

import { BASE_API_URL } from '@/utils/Constants';

const BASE_CATEOGRY_GROUPS_URL = BASE_API_URL + "category_groups";

export enum CategoryGroupsMixinTags {
  Index = 'CategoryGroupIndex',
  Show = 'CategoryGroupShow',
  Update = 'UpdateCategoryGroup',
  Create = 'CreateCategoryGroup',
  Destroy = 'DestroyCategoryGroup',
  CreateAndAssociate = 'CreateAndAssociateCategoryGroup'
}

@Component
export default class CategoryGroupsMixin extends Vue {
  getCategoryGroups(data: any, tag: string) {
    return this.$httpGetQuery(BASE_CATEOGRY_GROUPS_URL, tag, data);
  }

  getCategoryGroup(id: number | string, tag: string) {
    return this.$httpGet(BASE_CATEOGRY_GROUPS_URL + "/" + id, tag);
  }

  createCategoryGroup(data: any, tag: string) {
    return this.$httpPost(BASE_CATEOGRY_GROUPS_URL, tag, data);
  }

  updateCategoryGroup(id: number | string, data: any, tag: string) {
    return this.$httpPut(BASE_CATEOGRY_GROUPS_URL + "/" + id, tag, data);
  }

  destoryCategoryGroup(id: number | string, tag: string) {
    return this.$httpDelete(BASE_CATEOGRY_GROUPS_URL + "/" + id, tag);
  }

  createAndAssociateCategoryGroup(data: any, tag: string) {
    return this.$httpPost(`${BASE_CATEOGRY_GROUPS_URL}/create_and_associate`, tag, data);
  }
}
